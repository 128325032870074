.container {
  position: fixed;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  z-index: 1000;

  animation: fadeIn 0.3s ease;

  &.bg-color {
    background-color: #323232;
  }
  &.text-color {
    color: #fff;
  }

  .close-button {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
