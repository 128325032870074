/// Scale
/// Utilities for scaling elements with transform.

.transform-none {
  transform: none;
}
.hover\:scale-105:hover {
  transform: scale(1.05);
}

.transform-none {
  transform: none;
}

/// Translate
/// Utilities for translating elements with transform.
.translate-x-1\/2 {
  transform: translateX(50%);
}

.translate-y-1\/2 {
  transform: translateY(50%);
}

/// Rotate
/// Utilities for rotating elements with transform.

$deg-list: (45, 90, 180);
@each $value in $deg-list {
  .rotate-#{$value} {
    transform: rotate(#{$value}deg);
  }
}
