/// Position
/// Utilities for controlling how an element is positioned in the DOM.
$layouts: (absolute, fixed, sticky, relative);

@each $value in $layouts {
  /// @group Position
  .#{$value} {
    position: #{$value};
  }
}

/// Top / Right / Bottom / Left
/// Utilities for controlling the placement of positioned elements.
$placement: (top, right, left, bottom);

@each $value in $placement {
  /// @group Top-Right-Bottom-Left
  .#{$value}-0 {
    #{$value}: 0;
  }
  /// @group Top-Right-Bottom-Left
  .#{$value}-100 {
    #{$value}: 100%;
  }
}
/// Utilities for controlling the placement of positioned elements.
/// This positive and negative REM units
$counter: 20 * 2;
$step: 0.25;

@for $i from 0 through $counter {
  $value: #{$i * 0.125}rem;
  $unit: escape-dot($i / 2);

  @each $rule, $attribute in $placement {
    .#{$rule}-#{$unit} {
      #{$rule}: $value;
    }

    .-#{$rule}-#{$unit} {
      #{$rule}: -#{$value};
    }
  }
}
/// @group Top-Right-Bottom-Left
.inset-0 {
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}

.inset-x-0 {
  right: 0px;
  left: 0px;
}

.inset-y-0 {
  top: 0px;
  bottom: 0px;
}

/// Z-Index
/// Utilities for controlling the stack order of an element.

/// @group Z-Index  -10 to -100
@for $i from 10 through 1 {
  $value: $i * 10;
  .-z-#{$value} {
    z-index: -#{$value};
  }
}

/// @group Z-Index 0 to 9
@for $i from 0 through 9 {
  $value: $i;
  .z-#{$value} {
    z-index: #{$value};
  }
}

/// @group Z-Index 10 to 90
@for $i from 1 through 9 {
  $value: $i * 10;

  .z-#{$value} {
    z-index: #{$value};
  }
}

/// @group Z-Index 100 to 1000
@for $i from 1 through 10 {
  $value: $i * 100;
  .z-#{$value} {
    z-index: #{$value};
  }
}
