.title {
  display: flex;
  justify-content: var(--title-justify, space-between);
  align-items: var(--title-align, center);

  &[data-type='chevron-ico'] {
    display: block;
    transition: transform 0.3s;
  }

  &[data-collapsed='true'] {
    [data-type='chevron-ico'] {
      transform: matrix(-1, 0, 0, -1, 0, 0);
    }
  }

  .heading {
    color: var(--gray-700);
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    margin: 0;
    [data-collapsed='true'] & {
      margin-block-end: 0.5rem;
    }

    &[tabindex='0'] {
      cursor: pointer;
    }
  }

  .toggle-button {
    cursor: pointer;
    color: inherit;
    background: transparent;
    border: none;
  }
}

.body {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition:
    grid-template-rows ease 0.6s,
    opacity ease 0.3s;

  & > * {
    overflow: hidden;
  }

  &[data-collapsed='true'] {
    & > * {
      animation: overflow-initial 1ms 0.5s forwards;
      @keyframes overflow-initial {
        to {
          overflow: initial;
        }
      }
    }
    grid-template-rows: 1fr;
    opacity: 1;
  }

  &[data-collapsed='false'] {
    overflow-x: hidden;
    pointer-events: none;

    & * {
      padding: 0px !important;
      transition: padding 0.6s ease-in;
    }

    & > * {
      animation: display-none 1ms 0.65s forwards;
      @keyframes display-none {
        to {
          display: none;
        }
      }
    }
  }
}
