.container {
  cursor: pointer;

  border: none;
  background: none;
  padding: 0;

  font-size: inherit;
  font-family: inherit;
  height: fit-content;

  &:disabled {
    cursor: not-allowed;
    transform: none;
    opacity: 0.5;
  }

  &[data-hidden='true'] {
    pointer-events: none;
  }
}
