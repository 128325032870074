/// Box Shadow
/// Utilities for controlling the box shadow of an element.
/// @group effects

.shadow-sm {
  box-shadow: 0 var(--shadow-offset-y, 1px) var(--shadow-blur, 2px)
    var(--shadow-spread, 0) var(--shadow-color, rgba(0, 0, 0, 0.05));
}

.shadow {
  box-shadow:
    0 var(--shadow-offset-y, 1px) 3px 0 var(--shadow-color, rgba(0, 0, 0, 0.1)),
    0 var(--shadow-offset-y, 1px) 2px -1px
      var(--shadow-color, rgba(0, 0, 0, 0.1));
}

.shadow-md {
  box-shadow:
    0 4px 6px -1px var(--shadow-color, rgba(0, 0, 0, 0.1)),
    0 2px 4px -2px var(--shadow-color, rgba(0, 0, 0, 0.1));
}

.shadow-lg {
  box-shadow:
    0 10px 15px -3px var(--shadow-color, rgba(0, 0, 0, 0.1)),
    0 4px 6px -4px var(--shadow-color, rgba(0, 0, 0, 0.1));
}

.shadow-xl {
  box-shadow:
    0 20px 25px -5px var(--shadow-color, rgba(0, 0, 0, 0.1)),
    0 8px 10px -6px var(--shadow-color, rgba(0, 0, 0, 0.1));
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px var(--shadow-color, rgba(0, 0, 0, 0.25));
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 var(--shadow-color, rgba(0, 0, 0, 0.05));
}

.shadow-none {
  box-shadow: 0 0 #0000;
}

.shadow-base {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

.shadow-strong {
  box-shadow: 1px 2px 2px #00000059;
}

/// Opacity
/// Utilities for controlling the opacity of an element.
/// @group effects

$counter: 10;
$step: 10;

@for $i from 0 through $counter {
  /// @group effect
  .opacity-#{$i * $step} {
    opacity: #{$i / 10};
  }
}

.fade-show {
  opacity: 1;
  transition: opacity 0.7s var(--timing-function);
  &[data-hidden='true'] {
    opacity: 0;
  }
}

$delays: (0, 75, 100, 150, 200, 300, 500, 700, 1000, 1500, 2000);

@each $delay in $delays {
  .fade-delay-#{$delay} {
    --fade-delay: #{$delay}ms;
  }
}

@mixin fade {
  --fade-duration: 0.7s;
  transition: opacity var(--fade-duration);
  transition-delay: var(--fade-delay, 0s);
  transition-behavior: allow-discrete;
}

.fade-in {
  @include fade;
  opacity: 1;
}

.fade-out {
  @include fade;
  opacity: 0;
}
