.container {
  border-radius: inherit;
  width: var(--size, inherit);
  height: var(--size, inherit);

  &[data-disabled='true'] {
    cursor: not-allowed;
    color: inherit;
  }

  &[data-hover-style-disabled='false'][data-disabled='false']:hover {
    box-shadow: 0 0 0 3px var(--gray-200);
    background-color: var(--gray-200);
    color: var(--gray-700);
  }

  [data-element='content'] {
    transition: opacity 0.5s;
    pointer-events: none;
    opacity: 0;
  }

  &[data-disabled='false']:hover {
    pointer-events: all;
    [data-element='content'] {
      opacity: 1;
    }
  }
}
