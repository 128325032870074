.container {
  width: fit-content;
  --error-color: var(--error-dark);

  input[type='number'] {
    &[data-invalid] {
      outline: none;
      box-shadow: none;
      border-color: var(--error-color);
    }

    &::-webkit-inner-spin-button {
      --offset: 6px;
      height: calc(100% - var(--offset));
      isolation: isolate;
      position: absolute;
      top: calc(var(--offset) / 2);
      right: 1px;
    }
  }

  &[data-invalid] {
    border-style: solid;
    border-inline: 1px;
    border-top-width: 1px;
    border-bottom-width: 6px;
    border-color: var(--error-color);
  }
}
