.animate-grow-down {
  animation: growDown 250ms ease-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@mixin skeleton-pulse() {
  animation: pulse 3s infinite;

  @media (prefers-reduced-motion) {
    animation: none;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    60% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin skeleton-wave() {
  position: relative;
  overflow: hidden;

  @media (prefers-reduced-motion) {
    animation: none;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1.6s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.skeleton-pulse {
  @include skeleton-pulse();
}

.skeleton-wave {
  @include skeleton-wave();
}
// default skeleton
.skeleton {
  @include skeleton-pulse();
}

.hidden-animate {
  animation: hidden-animate 0.5s forwards ease-in-out;

  @keyframes hidden-animate {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
}

.opacity-animate {
  animation: opacity-animate 1s forwards ease-in-out;

  @keyframes opacity-animate {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.hover\:scale-up-animate {
  &:hover {
    transform: scale(1.1);
    transition: transform 30ms ease-in-out;
  }
}

.hover\:opacity-animate {
  &:hover {
    @extend .opacity-animate;
  }
}

.display-grid-animate {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition:
    grid-template-rows ease 0.6s,
    opacity ease 0.6s;

  & > * {
    overflow: hidden;
  }

  animation: show 0.4s ease-out forwards;

  @keyframes show {
    to {
      grid-template-rows: 1fr;
      opacity: 1;
      padding-block: 0.75rem;
    }
  }
}

.rotate-animate {
  animation: rotate-animate 2.5s linear infinite;
  @keyframes rotate-animate {
    100% {
      transform: rotate(360deg);
    }
  }
}

.outline-maroon-transition {
  outline: 4px solid var(--maroon);
  transition: outline ease-out 700ms;
}

/// Transition Delay
/// Utilities for controlling the delay of CSS transitions.
$delays: (0, 75, 100, 150, 200, 300, 500, 700, 1000);

@each $delay in $delays {
  .delay-#{$delay} {
    transition-delay: #{$delay}ms;
  }
  .delay-animation-#{$delay} {
    animation-delay: #{$delay}ms;
  }
}
